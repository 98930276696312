<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :color="promptColorClass">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";

// services
import {
  fullRecordName,
  getRecordTypeName
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { actionResultType } from "@/model/action/actionModel";
import { iconCopy } from "@/design/icon/iconConst";

export default {
  name: "CopyRecordDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin],
  props: {
    sourceRecord: undefined,
    destinationRecord: undefined,
    copyEvent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      iconCopy: iconCopy
    };
  },

  computed: {
    /**
     * Move Record Operation
     * @return {{name: string, icon: string, label: string}} Move Record Operation
     */
    operation() {
      return findRecordOperation(recordOperation.Move);
    },

    /**
     * Copy Record Operation Label
     * @return {string} Copy Record Operation Label
     */
    operationLabel() {
      return "Copy";
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.iconCopy;
    },

    /**
     * Get Copy Record Dialog title
     * @return {string} Copy Record Dialog title
     */
    dialogTitle() {
      return this.sourceRecord?.isLink
        ? `${this.operationLabel} Record Shortcut`
        : `${this.operationLabel} Record`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${getRecordTypeName(this.sourceRecord)} ${
        this.sourceRecord?.isLink ? "Shortcut" : ""
      }: '${fullRecordName(this.sourceRecord)} to ${getRecordTypeName(
        this.destinationRecord
      )}: '${fullRecordName(this.destinationRecord)}'`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.sourceRecord?.isLink
        ? `${this.operationLabel} Shortcut`
        : this.operationLabel;
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Do you want to ${this.action}?`;
    },

    /**
     * current Prompt Type
     * remarks:  overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.success;
    },

    /**
     * Prompt Hint
     * remarks:  overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return `Copying will create a copy of ${getRecordTypeName(
        this.sourceRecord
      )} ${this.sourceRecord?.isLink ? "Shortcut" : ""} in ${fullRecordName(
        this.destinationRecord
      )}   `;
    }
  },

  methods: {
    /**
     * Performs Copy Record
     */
    async onOk() {
      try {
        this.clearAlert();
        const result = await this.copyEvent();
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      }
    }
  }
};
</script>
